<template>
  <v-card
    class='pa-5 ma-5 rounded-lg w-100'
    elevation='3'
  >
    <v-tabs-items v-model='selectedTab'>
      <v-tab-item
        v-if='selectedTab === 0'
        :value='0'
      >
          <v-data-table
            v-model='selected'
            :headers='headers'
            :items='visibleActiveEmployeesItems'
            :loading='loading || (loadingCurrentRescreen)'
            :options.sync='allEmployeesOptions'
            :server-items-length='tableTotalItems'
            :items-per-page='10'
            checkboxColor='primary'
            class='pa-4'
            item-key='id'
            loading-text='Loading employees... Please wait'
            show-select
            must-sort
          >
            <template #top>
              <v-toolbar flat>
                <v-toolbar-title class='font-weight-bold'>
                  {{ sections.employees || 'Employees title' }}
                </v-toolbar-title>
                <v-divider
                  class='mx-4'
                  inset
                  vertical
                ></v-divider>
                <v-toolbar-title class='font-weight-bold text--primary'>
                  {{ getPercentageOfLoggedEmployees(activeEmployees) }}% Logged In
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model='searchActiveEmployees'
                  class='mr-4'
                  dense
                  hide-details
                  label='Search'
                  outlined
                  prepend-inner-icon='mdi-magnify'
                  single-line
                  @input='searchAllEmployees'
                >
                </v-text-field>
                <!-- add new popup -->
                <v-dialog
                  v-model='dialog'
                  max-width='1000px'
                  transition='dialog-bottom-transition'
                >
                  <v-card class='pt-8'>
                    <v-form ref='form' v-model='valid'>
                      <v-card-title class='px-7 py-0 mb-6'>
                        <span class='headline font-weight-bold'>{{ formTitle }}</span>
                        <v-btn
                            v-if="formTitle === 'New Employee' && !searchNewEmployee"
                            class='px-14 ma-4 font-weight-bold white--text'
                            color='primary'
                            large
                            @click='searchNewEmployee = true'
                        >Search Employee
                        </v-btn>
                      </v-card-title>
                      <v-container v-if='searchNewEmployee'>
                        <v-card-text class='py-2 px-4'>
                          <v-card-title>
                            <span class='headline font-weight-bold'>Search Employee</span>
                          </v-card-title>
                          <v-container>
                            <v-row
                                class='mb-n10'
                                md='4'
                                sm='6'
                            >
                              <v-col>
                                <p class='font-weight-bold'>First Name</p>
                                <v-text-field
                                    v-model='selectedEmployee.firstName'
                                    dense
                                    outlined
                                    placeholder='i.e John'
                                ></v-text-field>
                              </v-col>
                              <v-col>
                                <p class='font-weight-bold'>Middle Name</p>
                                <v-text-field
                                    v-model='selectedEmployee.middleName'
                                    dense
                                    outlined
                                    placeholder='i.e Phill'
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row
                                class='mb-n10'
                                md='4'
                                sm='6'
                            >
                              <v-col>
                                <p class='font-weight-bold'>Last Name</p>
                                <v-text-field
                                    v-model='selectedEmployee.lastName'
                                    dense
                                    outlined
                                    placeholder='i.e Williams'
                                ></v-text-field>
                              </v-col>
                              <v-col>
                                <p class='font-weight-bold'>VUID</p>
                                <v-text-field
                                    v-model='selectedEmployee.vuid'
                                    dense
                                    outlined
                                    placeholder='i.e 123'
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row
                                class='mb-n10'
                                md='4'
                                sm='6'
                            >
                              <v-col>
                                <p class='font-weight-bold'>Mailing Address</p>
                                <v-text-field
                                    v-model='selectedEmployee.mailAddress'
                                    dense
                                    outlined
                                    placeholder='i.e Lincoln Square 123'
                                ></v-text-field>
                              </v-col>
                              <v-col>
                                <p class='font-weight-bold'>Mailing City</p>
                                <v-text-field
                                    v-model='selectedEmployee.mailCity'
                                    dense
                                    outlined
                                    placeholder='i.e Chicago'
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row
                                class='mb-n10'
                                md='4'
                                sm='6'
                            >
                              <v-col>
                                <p class='font-weight-bold'>Birth Year</p>
                                <year-picker v-model='selectedEmployee.birthDate'/>
                              </v-col>
                              <v-col>
                                <p class='font-weight-bold'>Mailing Zip Code</p>
                                <v-text-field
                                    v-model='selectedEmployee.mailZip'
                                    dense
                                    outlined
                                    placeholder='i.e 9851123'
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row
                                class='mb-n10'
                                md='4'
                                sm='6'
                            >
                              <v-col>
                                <p class='font-weight-bold'>County</p>
                                <v-select
                                    v-model='selectedEmployee.county'
                                    :items='itemsCountySearch'
                                    class='mt-3'
                                    dense
                                    label='County'
                                    outlined
                                    placeholder='County'
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-card-text
                                v-if='emptySearch'
                                class='pl-0 mt-2 pb-0'
                            >
                              This person was not found, but you can add them manually
                            </v-card-text>
                          </v-container>
                        </v-card-text>
                        <v-data-table
                            v-if='isEmployeesEmpty'
                            v-model='selectedNewEmployee'
                            :headers='headersEmployee'
                            :items='selectedEmployees'
                            color='primary'
                            item-key='id'
                            label='Employees'
                            show-select
                            single-select
                            @item-selected='select($event)'
                        ></v-data-table>
                        <v-card-actions class='justify-center'>
                          <v-btn
                              :disabled='!validSearch'
                              :loading='loadingSearch'
                              class='px-14 ma-4 font-weight-bold white--text'
                              color='primary'
                              large
                              @click='SearchEmployee'
                          >
                            Search
                          </v-btn>
                          <v-btn
                              v-if='successSearch'
                              class='px-14 ma-4 font-weight-bold white--text'
                              color='primary'
                              large
                              @click='selectNewEmployee'
                          >
                            Select Employee
                          </v-btn>
                          <v-btn
                              v-if='emptySearch'
                              class='px-14 ma-4 font-weight-bold white--text'
                              color='primary'
                              large
                              @click='addEmployeeManualy'
                          >
                            Add Employee manually
                          </v-btn>
                        </v-card-actions>
                      </v-container>
                      <v-container v-else class="py-0">
                        <v-row class="ma-0 px-1 py-0">
                          <v-col class="py-0" cols="6">
                            <p class='font-weight-bold'>First Name</p>
                            <v-text-field
                                v-model='editedItem.firstName'
                                :disabled='disabledAfterSearch.firstName'
                                :rules="[(v) => !!v || 'Field is required']"
                                dense
                                outlined
                                placeholder='i.e John'
                                required
                                @change='ifFieldChanged'
                            ></v-text-field>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <p class='font-weight-bold'>Middle Name</p>
                            <v-text-field
                                v-model='editedItem.secondName'
                                :disabled='disabledAfterSearch.secondName'
                                dense
                                outlined
                                placeholder='i.e Phill'
                                @change='ifFieldChanged'
                            ></v-text-field>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <p class='font-weight-bold'>Last Name</p>
                            <v-text-field
                                v-model='editedItem.lastName'
                                :disabled='disabledAfterSearch.lastName'
                                :rules="[(v) => !!v || 'Field is required']"
                                dense
                                outlined
                                placeholder='i.e Williams'
                                required
                                @change='ifFieldChanged'
                            ></v-text-field>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <p class='font-weight-bold'>Birth Year</p>
                            <year-picker
                                v-model='editedItem.birthDate'
                                :disabled='disabledAfterSearch.birthDate'
                            />
                          </v-col>

                          <v-col class="py-0" cols="6">
                            <p class='font-weight-bold'>Mobile Phone</p>
                            <v-text-field
                                v-model='editedItem.mobilePhone'
                                dense
                                outlined
                                placeholder='i.e 2123456789'
                            ></v-text-field>
                          </v-col>
                          <v-col class="py-0" cols="6"></v-col>
                          <v-col cols='12' class='pb-5 pt-0'>
                            <hr>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <p class='font-weight-bold'>Mailing Address</p>
                            <v-text-field
                                id='addressInput'
                                ref='addressInput'
                                v-model='editedItem.mailAddress'
                                :disabled='disabledAfterSearch.mailAddress'
                                dense
                                outlined
                                placeholder='i.e Lincoln Square 123'
                                @change='ifFieldChanged'
                            ></v-text-field>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <p class='font-weight-bold'>Mailing City</p>
                            <v-text-field
                                id='cityInput'
                                ref='cityInput'
                                v-model='editedItem.mailCity'
                                :disabled='disabledAfterSearch.mailCity'
                                dense
                                outlined
                                placeholder='i.e Chicago'
                                @change='ifFieldChanged'
                            ></v-text-field>
                          </v-col>

                          <v-col class="py-0" cols="6">
                            <p class='font-weight-bold'>Mailing State</p>
                            <v-text-field
                                id='stateInput'
                                ref='stateInput'
                                v-model='editedItem.mailState'
                                :disabled='disabledAfterSearch.mailState'
                                dense
                                outlined
                                placeholder='i.e Illinois'
                                @change='ifFieldChanged'
                            ></v-text-field>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <p class='font-weight-bold'>Mailing Zip Code</p>
                            <v-text-field
                                id='zipCodeInput'
                                ref='zipCodeInput'
                                v-model='editedItem.mailZip'
                                :disabled='disabledAfterSearch.mailZip'
                                dense
                                outlined
                                placeholder='i.e 9851123'
                                @change='ifFieldChanged'
                            ></v-text-field>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <p class='font-weight-bold'>County</p>
                            <v-select
                                id='countyInput'
                                ref='countyInput'
                                v-model='editedItem.county'
                                :disabled='disabledAfterSearch.county'
                                :items='itemsCounty'
                                dense
                                label='County'
                                outlined
                                placeholder='County'
                                required
                            ></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6"></v-col>
                          <v-col cols='12' class='pb-5 pt-0'>
                            <hr>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <p class='font-weight-bold'>Voter Registration Address</p>
                            <v-text-field
                                id='addressInput'
                                ref='addressInput'
                                v-model='editedItem.voterAddress'
                                :disabled='disabledAfterSearch.voterAddress'
                                dense
                                outlined
                                @change='ifFieldChanged'
                                placeholder='i.e Lincoln Square 123'
                            ></v-text-field>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <p class='font-weight-bold'>Voter Registration City</p>
                            <v-text-field
                                id='cityInput'
                                ref='cityInput'
                                v-model='editedItem.voterCity'
                                :disabled='disabledAfterSearch.voterCity'
                                dense
                                outlined
                                placeholder='i.e Chicago'
                                @change='ifFieldChanged'
                            ></v-text-field>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <p class='font-weight-bold'>Voter Registration State</p>
                            <v-text-field
                                id='stateInput'
                                ref='stateInput'
                                v-model='editedItem.voterState'
                                :disabled='disabledAfterSearch.voterState'
                                dense
                                outlined
                                placeholder='i.e Illinois'
                                @change='ifFieldChanged'
                            ></v-text-field>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <p class='font-weight-bold'>Voter Registration Zip Code</p>
                            <v-text-field
                                id='zipCodeInput'
                                ref='zipCodeInput'
                                v-model='editedItem.voterZip'
                                :disabled='disabledAfterSearch.voterZip'
                                dense
                                outlined
                                placeholder='i.e 9851123'
                                @change='ifFieldChanged'
                            ></v-text-field>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <p class='font-weight-bold'>Voter Registration County</p>
                            <v-select
                                ref='countyInput'
                                v-model='editedItem.voterCounty'
                                :items='itemsCounty'
                                class='mt-3'
                                dense
                                item-value='value'
                                item-text='text'
                                label='Voter Registration County'
                                outlined
                                placeholder='Voter Registration County'
                            ></v-select>
                          </v-col>
                          <v-col class="py-0" cols="6"></v-col>
                          <v-col cols='12' class='pb-5 pt-0'>
                            <hr>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <p class='font-weight-bold'>Primary Email</p>
                            <v-text-field
                                v-model='editedItem.email'
                                :rules="[(v) => !!v || 'Field is required']"
                                dense
                                outlined
                                placeholder='i.e john@mydomain.com'
                                required
                            ></v-text-field>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <p class='font-weight-bold'>VUID</p>
                            <v-text-field
                                v-model='editedItem.vuid'
                                :disabled='disabledAfterSearch.vuid'
                                dense
                                outlined
                                placeholder='i.e 123'
                            >
                            </v-text-field>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <p class='font-weight-bold'>Reports To</p>
                            <v-autocomplete
                                v-model='editedItem.reportsTo'
                                :items='employeesToReport'
                                :rules="[(v) => !!v || 'Field is required']"
                                dense
                                item-text='fullName'
                                item-value='id'
                                label='Who do you report to?'
                                outlined
                                required
                            ></v-autocomplete>
                          </v-col>
                          <v-col class="py-0" cols="6">
                            <p class='font-weight-bold'>Hierarchy Level</p>
                            <v-select
                                v-model='editedItem.employeeRoleId'
                                :items='employeeRoles'
                                :rules="[(v) => !!v || 'Field is required']"
                                dense
                                item-text='description'
                                item-value='id'
                                label='Select your role'
                                outlined
                                required
                            ></v-select>
                          </v-col>
                          <v-col sm='6' md='6' class='pl-3'>
                            <p class='font-weight-bold'>Employee Eligibility</p>
                            <div class='d-flex align-center'>
                              <v-checkbox
                                  v-model='editedItem.inelegible'
                                  class='checkbox'
                                  color='warning'
                              >
                              </v-checkbox>
                              <span style='font-size: 16px;'>This employee is ineligible for voting campaigns</span>
                            </div>
                            <p
                                v-show='editedItem.inelegible'
                                class='font-weight-bold warning--text px-4'
                            >
                              Warning: An Employee with the ineligible option selected will be denied the
                              possibility
                              of scheduling time for voting or viewing the MCBA Recommendations.
                            </p>
                          </v-col>
                          <v-col
                              md='6'
                              sm='6'
                              v-if='editedItem.inelegible'
                          >
                            <p class='font-weight-bold'>Eligibility Detail</p>
                            <v-select
                                :items='eligibilityDetails'
                                v-model='editedItem.eligibilityDetailId'
                                label='Eligibility Detail'
                                dense
                                :rules="[(v) => !!v || 'Field is required']"
                                item-text='title'
                                item-value='id'
                                outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 px-1 py-0">
                          <v-col cols="12" class='pl-3'>
                            <p class='font-weight-bold'>Notification</p>
                            <div class='d-flex align-center'>
                              <v-checkbox
                                  v-model='editedItem.disabled_email_notification_ba'
                                  class='checkbox'
                              >
                              </v-checkbox>
                              <span style='font-size: 16px;'>Opt-Out of Scheduled MCBA Emails</span>
                            </div>
                            <div class='d-flex align-center' style='margin-top: -16px;'>
                              <v-checkbox
                                  v-model='editedItem.disabled_sms_notification_ba'
                              >
                              </v-checkbox>
                              <span style='font-size: 16px; '>Opt-Out of Scheduled MCBA SMS</span>
                            </div>
                          </v-col>
                        </v-row>
                        <v-card-actions class='d-flex direction-row justify-center'>
                          <v-btn
                              class='px-14 ma-4 font-weight-bold'
                              color='primary'
                              large
                              outlined
                              @click='close'
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                              v-if='editedIndex === -1'
                              :disabled='!valid'
                              class='px-14 ma-4 font-weight-bold white--text'
                              color='primary'
                              large
                              @click='createNewEmployee'
                          >
                            Submit
                          </v-btn>
                          <v-btn
                              v-if='editedIndex > -1'
                              :disabled='!valid'
                              :loading='loadingSave'
                              class='px-14 ma-4 font-weight-bold white--text'
                              color='primary'
                              large
                              @click='updateEmployee'
                          >
                            Save
                          </v-btn>
                          <v-btn
                              v-if='editedIndex > -1'
                              :disabled='!valid'
                              :loading='loadingSubmit'
                              class='px-14 ma-4 font-weight-bold white--text'
                              color='primary'
                              large
                              @click='screenEmployee'
                          >
                            Screen Employee
                          </v-btn>
                        </v-card-actions>
                      </v-container>
                    </v-form>
                  </v-card>
                </v-dialog>

                <v-btn
                  class='text-capitalize font-weight-bold ml-3'
                  color='primary'
                  height='40'
                  outlined
                  @click='downloadTemplate'
                >
                  Download Current Employees
                  <v-icon class='ml-4' left> mdi-download</v-icon>
                </v-btn>

                <v-dialog
                  v-model='dialogSuspend'
                  max-width='500px'
                >
                  <v-card class='d-flex flex-column align-start pa-2'>
                    <v-card-title class='text-center mb-5'>
                      <v-icon
                        class='mr-4 white--text'
                        color='warning'
                        large
                        left
                      >mdi-alert
                      </v-icon
                      >
                      Suspend Employee
                    </v-card-title>
                    <v-card-subtitle>
                      <b
                        v-if='singleSuspend'
                      >You are about to suspend the following employee:</b
                      >
                      <b
                        v-if='!singleSuspend'
                      >You are about to suspend the following employees:</b
                      >
                    </v-card-subtitle>
                    <p
                      v-if='singleSuspend'
                      class='ml-8 py-4 font-weight-bold secondary--text'
                    >
                      {{ editedItem.firstName }} {{ editedItem.lastName }}
                    </p>

                    <div v-if='!singleSuspend'>
                      <p
                        v-for='employee in selected'
                        v-bind:key='employee.id'
                        class='ml-8 font-weight-bold secondary--text'
                      >
                        {{ employee.firstName }} {{ employee.lastName }}
                      </p>
                    </div>
                    <v-card-actions class='align-self-center'>
                      <v-spacer></v-spacer>
                      <v-btn
                        class='px-14 mx-4 font-weight-bold white--text'
                        color='primary'
                        large
                        outlined
                        @click='closeSuspend'
                      >
                        Cancel
                      </v-btn
                      >
                      <v-btn
                        class='px-14 mx-4 font-weight-bold white--text'
                        color='primary'
                        large
                        @click='suspendEmployeeConfirm'
                      >
                        Confirm
                      </v-btn>

                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>

              <v-row class='d-flex justify-start align-center ml-4 my-4'>
                <v-btn
                  class='warning--text mr-2'
                  medium
                  outlined
                  @click='onSuspendBtnClick'
                >
                  <v-icon left>mdi-timer-off</v-icon>
                  Suspend
                </v-btn>
                <v-btn
                  :loading='loadingSendVerificationEmail'
                  class='text-capitalize font-weight-bold mr-2'
                  color='primary'
                  outlined
                  @click='sendSelectedInvites'
                >
                  Send Selected Invites
                </v-btn>
                <v-btn
                  :loading='loadingSelectedRescreen'
                  class='text-capitalize font-weight-bold'
                  color='success'
                  outlined
                  @click='reScreen(selected)'
                >
                  <v-icon class='mr-2'>mdi-update</v-icon>
                  Rescreen Selected
                </v-btn>
              </v-row>

              <!-- edit/create alert -->
              <v-alert
                v-model='showSuccessAlert'
                class='rounded-xl'
                color='success'
                dark
                dense
                dismissible
                icon='mdi-check-circle-outline'
                prominent
                transition='scale-transition'
                width='100%'
              >
                <h3
                  v-if='creating'
                  class='mt-2'
                >Employee created successfully!
                </h3>
                <h3
                  v-if='editing'
                  class='mt-2'
                >Employee modified successfully!
                </h3>
                <h3 v-if='showResendEmailAlert'>Email resend successfully!</h3>
              </v-alert>

              <v-alert
                v-model='showSuspendAlert'
                class='rounded-xl'
                color='warning'
                dark
                dense
                dismissible
                icon='mdi-information-outline'
                prominent
                transition='scale-transition'
                width='100%'
              >
                <h3 class='mt-2'>Employee Suspended</h3>
                <p>
                  You can always incorporate them back going to
                  <b>"Suspended Employees"</b> at the bottom of this page
                </p>
              </v-alert>
            </template>

            <template #item.fullName={item}>
              <span
                class='text-decoration-underline showPointer'
                @click='onEmployeeClick(item)'
              >
                {{ item.fullName }}
              </span>
            </template>
            <template #item.ineligible={item}>
              <i v-if="!item.ineligible" style="font-size: 20px" class="w-2 mdi-checkbox-marked green--text mdi"></i>
              <i v-else  style="font-size: 20px" class="mdi mdi-close-circle red--text"></i>
            </template>
            <template #item.reportsToFullName={item}>
              <span>
                {{ item.reportsToFullName }}
              </span>
            </template>
            <template #item.hierarchyDescription={item}>
              <span>
                {{ item.hierarchyDescription }}
              </span>
            </template>
            <template #no-data>
              <span>There are no active employees at the moment.</span>
            </template>
            <template #item.lastLoginDate={item}>
              <div
                v-if='item.lastLoginDate'
                class='d-flex align-center flex-column'
              >
                <span>{{ item.lastLoginDate.split(' ')[0] }}</span>
                <span>{{ item.lastLoginDate.split(' ')[1] }}</span>
              </div>
              <button
                v-else-if='item.invited'
                :disabled="loadingResendRegistration && item.id === employeeIndex"
                @click='resendRegistration(item.id)'
                class='teal lighten-2 white--text rounded-sm v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small'
              >
                Resend Invite
              </button>
              <button
                v-else-if='!item.invited'
                :disabled="loadingSendRegistration && item.id === employeeIndex"
                @click='sendRegistration(item.id)'
                class='teal lighten-2 white--text rounded-sm v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small'
              >
                Send Invite
              </button>
            </template>
            <template #item.actions={item}>
              <div class="d-flex flex-row flex-wrap">
                <button
                  @click="editItem(item)"
                  class="pa-2 ma-1 primary white--text rounded-sm d-flex justify-center align-center"
                  :disabled="loadingEditEmployee && item.id === employeeIndex"
                >
                  <img v-if="loadingEditEmployee && item.id === employeeIndex" width="20px" height="20px" :src="loader" alt="loader">
                  <i v-else style="line-height:20px; font-size: 20px" class="mdi mdi-pencil"></i>
                </button>
                <button
                  @click="suspendItem(item)"
                  class="pa-2 ma-1 warning white--text rounded-sm d-flex justify-center align-center"
                >
                  <i style="line-height:20px; font-size: 20px" class="mdi mdi-timer-off"></i>
                </button>
                <button
                  @click="reScreen(item)"
                  class="pa-2 ma-1 success white--text rounded-sm d-flex justify-center align-center"
                  :disabled='loadingCurrentRescreen && item.id === employeeIndex'
                >
                  <img v-if="loadingCurrentRescreen && item.id === employeeIndex" width="20px" height="20px" :src="loader" alt="loader">
                  <i v-else style="line-height:20px; font-size: 20px" class="mdi mdi-update"></i>
                </button>
              </div>
            </template>
          </v-data-table>
      </v-tab-item>
      <v-tab-item
        v-else-if='selectedTab === 1'
        :value='1'
      >
          <v-data-table
            v-model='suspendedSelected'
            :headers='headers'
            :items='visibleSuspendedEmployeesItems'
            :loading='loadingSuspended'
            :options.sync='suspendedEmployeesOptions'
            :server-items-length='tableTotalItems'
            :items-per-page='10'
            checkboxColor='primary'
            class='elevation-1 pa-4'
            item-key='id'
            loading-text='Loading employees... Please wait'
            show-select
          >
            <template #top>
              <v-toolbar flat>
                <v-toolbar-title class='font-weight-bold'>Suspended Employees</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model='searchSuspendedEmployees'
                  class='mr-4'
                  dense
                  hide-details
                  label='Search'
                  outlined
                  prepend-inner-icon='mdi-magnify'
                  single-line
                  @input='searchSuspendEmployees'
                >
                </v-text-field>
              </v-toolbar>
              <v-dialog
                v-model='dialogReincorporate'
                max-width='500px'
              >
                <v-card class='d-flex flex-column align-start pa-2'>
                  <v-card-title class='text-center mb-5'>
                    <v-icon
                      class='mr-4 white--text'
                      color='success'
                      large
                      left
                    >mdi-alert
                    </v-icon>
                    Reincorporate Employee
                  </v-card-title>
                  <v-card-subtitle>
                    <b v-if='singleReincorporate'>
                      You are about to reincorporate the following employee:
                    </b>
                    <b v-if='!singleReincorporate'>
                      You are about to reincorporate the following employees:
                    </b>
                  </v-card-subtitle>
                  <p
                    v-if='singleReincorporate'
                    class='ml-8 py-4 font-weight-bold secondary--text'
                  >
                    {{ editedItem.firstName }} {{ editedItem.lastName }}
                  </p>
                  <div v-if='!singleReincorporate'>
                    <p
                      v-for='employee in suspendedSelected'
                      v-bind:key='employee.id'
                      class='ml-8 font-weight-bold secondary--text'
                    >
                      {{ employee.firstName }} {{ employee.lastName }}
                    </p>
                  </div>
                  <v-card-actions class='align-self-center'>
                    <v-spacer></v-spacer>
                    <v-btn
                      class='px-14 mx-4 font-weight-bold white--text'
                      color='primary'
                      large
                      outlined
                      @click='closeReincorporate'
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      class='px-14 mx-4 font-weight-bold white--text'
                      color='primary'
                      large
                      @click='reincorporateEmployeeConfirm'
                    >
                      Confirm
                    </v-btn>

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model='dialogDelete'
                max-width='500px'
              >
                <v-card class='d-flex flex-column align-start pa-2'>
                  <v-card-title class='text-center mb-5'>
                    <v-icon
                      class='mr-4 white--text'
                      color='error'
                      large
                      left
                    >
                      mdi-alert
                    </v-icon>
                    Delete Employee
                  </v-card-title>
                  <v-card-subtitle>
                    <b v-if='singleDelete'>
                      You are about to delete the following employee:
                    </b>
                    <b v-if='!singleDelete'>
                      You are about to delete the following employees:
                    </b>
                  </v-card-subtitle>
                  <p
                    v-if='singleDelete'
                    class='ml-8 py-4 font-weight-bold secondary--text'
                  >
                    {{ editedItem.firstName }} {{ editedItem.lastName }}
                  </p>

                  <div v-if='!singleDelete'>
                    <p
                      v-for='employee in suspendedSelected'
                      v-bind:key='employee.id'
                      class='ml-8 font-weight-bold secondary--text'
                    >
                      {{ employee.firstName }} {{ employee.lastName }}
                    </p>
                  </div>
                  <v-card-actions class='align-self-center'>
                    <v-spacer></v-spacer>
                    <v-btn
                      class='px-14 mx-4 font-weight-bold white--text'
                      color='primary'
                      large
                      outlined
                      @click='closeDelete'
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      class='px-14 mx-4 font-weight-bold white--text'
                      color='primary'
                      large
                      @click='openDeleteConfirmation'
                    >
                      Confirm
                    </v-btn>

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model='dialogDeleteConfirmation'
                max-width='550px'
              >
                <v-card class='d-flex flex-column align-start pa-2'>
                  <v-card-title class='text-center mb-5'>
                    <v-icon
                      class='mr-4 white--text'
                      color='error'
                      large
                      left
                    >
                      mdi-alert
                    </v-icon>
                    Confirm Delete Employee
                  </v-card-title>
                  <v-card-subtitle>
                    <b v-if='singleDelete'>
                      Are you sure you want to delete this employee permanently?
                      <span class='error--text'>
                  There is no way to undo this action.
                </span>
                    </b>
                    <b v-if='!singleDelete'>
                      Are you sure you want to delete these employees permanently?
                      <span class='error--text'>
                  There is no way to undo this action.
                </span>
                    </b>
                  </v-card-subtitle>
                  <p
                    v-if='singleDelete'
                    class='ml-8 py-4 font-weight-bold secondary--text'
                  >
                    {{ editedItem.firstName }} {{ editedItem.lastName }}
                  </p>

                  <div v-if='!singleDelete'>
                    <p
                      v-for='employee in selected'
                      v-bind:key='employee.id'
                      class='ml-8 font-weight-bold secondary--text'
                    >
                      {{ employee.firstName }} {{ employee.lastName }}
                    </p>
                  </div>

                  <v-card-actions class='align-self-center'>
                    <v-spacer></v-spacer>
                    <v-btn
                      class='px-14 mx-4 font-weight-bold white--text'
                      color='primary'
                      large
                      outlined
                      @click='closeDeleteConfirmation'
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      class='px-14 mx-4 font-weight-bold white--text'
                      color='error'
                      large
                      @click='deleteItemConfirm'
                    >
                      Yes, Delete Now
                    </v-btn>

                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-row
                v-if='suspendedSelected.length > 0'
                class='d-flex justify-start align-center ml-4 my-4'
              >
                <v-btn
                  class='error--text mr-4'
                  medium
                  outlined
                  @click='onDeleteBtnClick'
                >
                  <v-icon left>mdi-close-thick</v-icon>
                  Delete
                </v-btn>
                <v-btn
                  class='success--text'
                  medium
                  outlined
                  @click='onReincorporateBtnClick'
                >
                  <v-icon left>mdi-undo</v-icon>
                  Reincorporate
                </v-btn>
              </v-row>

              <!-- delete alert -->
              <v-alert
                v-model='showDeleteAlert'
                class='rounded-xl'
                color='grey'
                dark
                dense
                dismissible
                icon='mdi-information-outline'
                prominent
                transition='scale-transition'
                width='100%'
              >
                <h3
                  v-if='!singleDelete'
                  class='mt-2'
                >Employees Deleted
                </h3>
                <h3
                  v-if='singleDelete'
                  class='mt-2'
                >Employee Deleted
                </h3>

                <p v-if='singleDelete'>Selected employee was successfully deleted.</p>
                <p v-if='!singleDelete'>
                  Selected employees were successfully deleted.
                </p>
              </v-alert>

              <v-alert
                v-model='showReincorporateAlert'
                class='rounded-xl'
                color='success'
                dark
                dense
                dismissible
                icon='mdi-information-outline'
                prominent
                transition='scale-transition'
                width='100%'
              >
                <h3
                  v-if='singleReincorporate'
                  class='mt-2'
                >
                  Employee Reincorporated
                </h3>
                <p v-if='singleReincorporate'>
                  Selected employee was successfully reincorporated.
                </p>

                <h3
                  v-if='!singleReincorporate'
                  class='mt-2'
                >
                  Employee Reincorporated
                </h3>
                <p v-if='!singleReincorporate'>
                  Selected employees were successfully reincorporated.
                </p>
              </v-alert>
            </template>
            <template #item.fullName={item}>
              <span>{{ item.fullName }}</span>
            </template>
            <template #item.ineligible={item}>
              <v-icon v-if="!item.ineligible"  height='20' width='20' color="green">mdi-checkbox-marked</v-icon>
              <v-icon  v-else height='20' width='20' color="red">mdi-close-circle</v-icon>
            </template>
            <template #item.reportsToFullName={item}>
        <span>
          {{ item.reportsToFullName }}
        </span>
            </template>
            <template #item.hierarchyDescription={item}>
        <span>
          {{ item.hierarchyDescription }}
        </span>
            </template>

            <template #item.actions={item}>
              <v-icon
                class='mr-2 my-1 pa-2 error white--text rounded-sm'
                dense
                @click='deleteItem(item)'
              >
                mdi-close-thick
              </v-icon>
              <v-icon
                class='mr-2 my-1 pa-2 success white--text rounded-sm'
                dense
                @click='reincorporateItem(item)'
              >
                mdi-undo
              </v-icon>
            </template>
            <template #no-data>
              <span>There are no suspended employees at the moment.</span>
            </template>
          </v-data-table>
      </v-tab-item>
      <v-tab-item
        v-else-if='selectedTab === 2'
        :value='2'
      >
        <MismatchEmployeesTable/>
      </v-tab-item>
    </v-tabs-items>
    <v-tabs
      v-model='selectedTab'
      align-with-title
    >
      <v-tab
        v-for='item in tabs'
        :key='item.id'
      >
        {{ item.title }}
      </v-tab>
    </v-tabs>
    <rescreen-dialog
      v-if='rescreenData && rescreenDialog'
      :data='rescreenData'
      :is-open='rescreenDialog'
      @close-dialog='closeRescreenDialog'
    />
  </v-card>
</template>

<script>
import YearPicker from '@/components/year-picker'
import employee from '../../services/employee'
import { mapActions, mapGetters, mapState } from 'vuex'
import MismatchEmployeesTable from '../../components/MismatchEmployeesTable'
import RescreenDialog from '@/components/RescreenDialog.vue'
import loader from '../../assets/images/loader.png'

export default {
  components: { RescreenDialog, MismatchEmployeesTable, YearPicker },
  data() {
    return {
      allEmployeesOptions: {},
      suspendedEmployeesOptions: {},
      eligibilityDetails: [],
      tableTotalItems: 0,
      rescreenData: [],
      rescreenDialog: false,
      isChanged: false,
      setTimeoutTimer: null,
      loadingSelectedRescreen: false,
      loadingCurrentRescreen: false,
      loadingSendRegistration: false,
      loadingSendVerificationEmail: false,
      selectedTab: 0,
      tabs: [
        { id: 0, title: 'Active employees' },
        { id: 1, title: 'Suspended employees' },
        { id: 2, title: 'Employee Matching Exceptions' },
        // { id: 3, title: 'Upload History' }
      ],
      loadingSave: false,
      loadingSubmit: false,
      loadingSearch: false,
      emptySearch: false,
      successSearch: false,
      disabledAfterSearch: {
        firstName: false,
        secondName: false,
        lastName: false,
        vuid: false,
        mailAddress: false,
        mailCity: false,
        mailState: false,
        mailZip: false,
        voterAddress: false,
        voterCity: false,
        voterState: false,
        voterZip: false,
        birthDate: false,
        county: false
      },
      itemsCountySearch: [],
      validSearch: false,
      selectedEmployees: [],
      selectedEmployee: {
        firstName: '',
        middleName: '',
        lastName: '',
        vuid: '',
        mailAddress: '',
        mailCity: '',
        birthDate: '',
        mailZip: '',
        county: null
      },
      selectedNewEmployee: [],
      searchNewEmployee: false,
      loadingEditEmployee: false,
      employeeIndex: -1,
      showResendEmailAlert: false,
      loadingResendRegistration: false,
      phoneRules: [
        v => /^\d+$/.test(v) || 'Value must be a number',
        v => v?.length <= 10 || 'Value must be equal to 10 characters',
        v => v?.length >= 10 || 'Value must be equal to 10 characters'
      ],
      editing: false,
      creating: false,
      employeesToReport: [],
      dialog: false,
      dialogDelete: false,
      dialogSuspend: false,
      dialogReincorporate: false,
      suspend: false,
      reincorporate: false,
      valid: true,
      fullName: '',
      loading: true,
      loadingSuspended: true,
      singleDelete: true,
      singleSuspend: true,
      singleReincorporate: true,
      dialogDeleteConfirmation: false,
      searchActiveEmployees: '',
      searchSuspendedEmployees: '',
      showSuccessAlert: false,
      showDeleteAlert: false,
      showSuspendAlert: false,
      showReincorporateAlert: false,
      selected: [],
      suspendedSelected: [],
      headers: [
        { text: 'Name', align: 'start', value: 'fullName' },
        { text: 'Eligibility', align: 'center', value: 'ineligible', sortable: false},
        { text: 'County', value: 'county.text', sortable: false },
        { text: 'Hierarchy', value: 'hierarchyDescription' },
        { text: 'Reports To', value: 'reportsToFullName' },
        { text: 'Email', value: 'email' },
        { text: 'Last Login Time', value: 'lastLoginDate', align: 'center', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      headersEmployee: [
        { text: 'First Name', value: 'first_name', sortable: false },
        { text: 'Middle Name', value: 'middle_name', sortable: false },
        { text: 'Last Name', value: 'last_name', sortable: false },
        { text: 'VUID', value: 'vuid', sortable: false },
        { text: 'Address', value: 'address', sortable: false },
        { text: 'City', value: 'city', sortable: false },
        { text: 'Birth Date', value: 'birth_day', sortable: false },
        { text: 'Zip Code', value: 'zip', sortable: false },
        { text: 'County', value: 'county.text', sortable: false }
      ],
      activeEmployees: [],
      visibleActiveEmployeesItems: [],
      suspendedEmployees: [],
      visibleSuspendedEmployeesItems: [],
      itemsCounty: [],
      employeeRoles: [],
      editedIndex: -1,
      editedItem: {
        firstName: '',
        voterCounty: null,
        secondName: '',
        lastName: '',
        userName: '',
        email: '',
        secondaryEmail: '',
        homePhone: '',
        workPhone: '',
        mobilePhone: '',
        mailAddress: '',
        mailZip: '',
        mailCity: '',
        mailState: '',
        voterAddress: '',
        voterZip: '',
        voterCity: '',
        voterState: '',
        county: null,
        vuid: '',
        birthDate: '',
        employeeRoleId: '',
        reportsTo: '',
        profilePicture: '',
        inelegible: false,
        disabled_email_notification_ba: false,
        disabled_sms_notification_ba: false,
        eligibilityDetailId: null
      },
      defaultItem: {
        firstName: '',
        secondName: '',
        lastName: '',
        userName: '',
        email: '',
        secondaryEmail: '',
        homePhone: '',
        workPhone: '',
        mobilePhone: '',
        mailAddress: '',
        mailZip: '',
        mailCity: '',
        mailState: '',
        voterAddress: '',
        voterZip: '',
        voterCity: '',
        voterState: '',
        county: null,
        vuid: '',
        birthDate: '',
        employeeRoleId: '',
        reportsTo: '',
        profilePicture: '',
        inelegible: false,
        disabled_email_notification_ba: false,
        disabled_sms_notification_ba: false
      },
      loader: loader
    }
  },
  computed: {
    ...mapState('cmsStore', ['sections']),
    ...mapGetters({
      getPercentageOfLoggedEmployees: 'employeeStore/getPercentageOfLoggedEmployees',
      currentUserID: 'currentUserID'
    }),
    isEmployeesEmpty() {
      return this.selectedEmployees?.length
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Employee' : 'Edit Employee'
    },
    downloadTemplateLink() {
      return `${ process.env.VUE_APP_DOWNLOAD_URL }api/employee-exports/${ this.currentUserID }`
    }
  },
  watch: {
    allEmployeesOptions: {
      async handler() {
        await this.getEmployees()
      },
      deep: true
    },
    suspendedEmployeesOptions: {
      async handler() {
        await this.getSuspendedEmployees()
      },
      deep: true
    },
    emptySearch() {
      if (this.emptySearch) {
        this.disabledAfterSearch = {
          firstName: false,
          secondName: false,
          lastName: false,
          vuid: false,
          mailAddress: false,
          mailCity: false,
          birthDate: false,
          mailZip: false,
          county: false,
          mailState: true
        }
      }
    },
    selectedEmployee: {
      handler() {
        this.validSearch = Object.values(this.selectedEmployee).some((el) => el !== '' && el !== null)
      },
      deep: true
    },
    formTitle() {
      if (this.formTitle == 'Edit Employee') {
        this.searchNewEmployee = false
        this.disabledAfterSearch = {
          firstName: false,
          secondName: false,
          lastName: false,
          vuid: false,
          mailAddress: false,
          mailCity: false,
          mailZip: false,
          voterAddress: false,
          voterCity: false,
          voterZip: false,
          birthDate: false,
          county: false
        }
      }
    },
    async dialog(val) {
      if (val) {
        if (!this.eligibilityDetails.length) {
          this.eligibilityDetails = await this.getEligibilityDetails()
        }
        await Promise.allSettled([
          this.getEmployeesToReport(),
          this.getEmployeesRoles()
        ])
        this.itemsCounty = await this.getCounties()
        this.itemsCountySearch = this.itemsCounty
        this.itemsCountySearch.unshift({ value: null, text: '' })
      } else {
        this.close()
      }
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },
  methods: {
    ...mapActions({
      getMemberSuspendedEmployees: 'employeeStore/getMemberSuspendedEmployees',
      getEmployeeInfoToEdit: 'employeeStore/getEmployeeInfoToEdit',
      getCounties: 'communicationStore/getCounties',
      searchEmployee: 'employeeStore/searchEmployee',
      resendVerificationEmail: 'userStore/resendVerificationEmail',
      sendVerificationEmail: 'userStore/sendVerificationEmail',
      reScreenEmployees: 'employeeStore/reScreenEmployees',
      getEligibilityDetails: 'employeeStore/getEligibilityDetails'
    }),
    downloadTemplate() {
      location.href = this.downloadTemplateLink
    },
    searchAllEmployees() {
      clearTimeout(this.setTimeoutTimer)
      this.setTimeoutTimer = setTimeout(() => this.allEmployeesOptions = { ...this.allEmployeesOptions, page: 1 }, 500)
    },
    searchSuspendEmployees() {
      clearTimeout(this.setTimeoutTimer)
      this.setTimeoutTimer = setTimeout(() => this.suspendedEmployeesOptions = {
        ...this.suspendedEmployeesOptions,
        page: 1
      }, 500)
    },
    async checkSelectEmployee(value) {
      if (!value.length && !Object.keys(value).length) {
        await this.$store.dispatch('snackbar', {
          show: true,
          color: 'warning',
          message: 'You need to select an employee!'
        })
        return false
      }
      return true
    },
    async reScreen(selectedEmployees) {
      const hasSelectedEmployee = await this.checkSelectEmployee(selectedEmployees)
      if (hasSelectedEmployee) {
        this.employeeIndex = -1
        let reScreenEmployeesIds = []
        if (selectedEmployees.length) {
          reScreenEmployeesIds = selectedEmployees.map(el => el.id)
          this.loadingSelectedRescreen = true
        } else {
          reScreenEmployeesIds.push(selectedEmployees.id)
          this.employeeIndex = selectedEmployees.id
          this.loadingCurrentRescreen = true
        }
        const result = await this.reScreenEmployees(reScreenEmployeesIds)
        this.checkRescreen(result)
        this.loadingSelectedRescreen = false
        this.loadingCurrentRescreen = false
        await this.getEmployees()
      }
    },
    checkRescreen(result) {
      this.rescreenData = result.data
      this.rescreenData.length ? this.rescreenDialog = true : null
    },
    async closeRescreenDialog() {
      this.rescreenData = []
      this.rescreenDialog = false
      await this.getEmployees()
    },
    ifFieldChanged() {
      this.isChanged = true
    },
    select($event) {
      this.successSearch = !!$event.value
    },
    addEmployeeManualy() {
      this.disabledAfterSearch.state = false
      this.searchNewEmployee = false
      this.editedItem = {
        firstName: '',
        secondName: '',
        lastName: '',
        mailAddress: '',
        mailCity: '',
        mailState: '',
        mailZip: '',
        voterAddress: '',
        voterZip: '',
        voterCity: '',
        voterState: '',
        vuid: '',
        birthDate: '',
        county: ''
      }
    },
    selectNewEmployee() {
      this.editedItem = {
        firstName: this.selectedNewEmployee[0].first_name,
        secondName: this.selectedNewEmployee[0].middle_name,
        lastName: this.selectedNewEmployee[0].last_name,
        vuid: this.selectedNewEmployee[0].vuid,
        mailAddress: this.selectedNewEmployee[0].mailAddress,
        mailCity: this.selectedNewEmployee[0].mailCity,
        mailZip: this.selectedNewEmployee[0].mailZip,
        mailState: 'TX',
        voterAddress: this.selectedNewEmployee[0].voterAddress,
        voterCity: this.selectedNewEmployee[0].voterCity,
        voterZip: this.selectedNewEmployee[0].voterZip,
        voterState: 'TX',
        birthDate: this.selectedNewEmployee[0].birth_day,
        county: this.selectedNewEmployee[0].county?.id,
        voterCounty: this.selectedNewEmployee[0].voterCounty?.id,
        disabledSmsNotificationBa: this.disabledSmsNotificationBa,
        disabledEmailNotificationBa: this.disabledEmailNotificationBa
      }
      this.checkDisabledFuilds()
      this.searchNewEmployee = false
    },
    checkDisabledFuilds() {
      for (let i in this.editedItem) {
        this.editedItem[i]
          ? this.disabledAfterSearch[i] = true
          : this.disabledAfterSearch[i] = false
      }
    },
    async SearchEmployee() {
      this.selectedNewEmployee = []
      this.loadingSearch = true
      this.successSearch = false
      const data = {
        first_name: this.selectedEmployee.firstName,
        middle_name: this.selectedEmployee.middleName,
        last_name: this.selectedEmployee.lastName,
        vuid: this.selectedEmployee.vuid,
        address: this.selectedEmployee.mailAddress,
        city: this.selectedEmployee.mailCity,
        birth_day: this.selectedEmployee.birthDate,
        zip: this.selectedEmployee.mailZip,
        county_id: this.selectedEmployee.county
      }
      this.selectedEmployees = []
      this.selectedEmployees = await this.searchEmployee(data)
      this.selectedEmployees?.length === 0 ? this.emptySearch = true : this.emptySearch = false
      this.loadingSearch = false
    },
    async sendSelectedInvites() {
      const hasSelectedEmployee = await this.checkSelectEmployee(this.selected)
      if (hasSelectedEmployee) {
        this.loadingSendVerificationEmail = true
        const employeeIds = this.selected.map(el => el.id)
        await this.sendVerificationEmail(employeeIds)
        await this.getEmployees()
        this.loadingSendVerificationEmail = false
      }
    },
    async sendRegistration(id) {
      this.employeeIndex = id
      this.loadingSendRegistration = true
      try {
        await this.sendVerificationEmail(id)
        await this.getEmployees()
      } catch {
        this.loadingSendRegistration = false
      }
      this.loadingSendRegistration = false
    },
    async resendRegistration(id) {
      this.employeeIndex = id
      this.loadingResendRegistration = true
      try {
        await this.resendVerificationEmail(id)
        await this.getEmployees()
      } catch {
        this.loadingResendRegistration = false
      }
      this.loadingResendRegistration = false
    },
    async getSuspendedEmployees() {
      this.suspendedEmployees = []
      this.visibleSuspendedEmployeesItems = []
      this.loadingSuspended = true
      const { itemsPerPage, page, sortBy, sortDesc } = this.suspendedEmployeesOptions
      const params = {
        page,
        per_page: itemsPerPage > 0 ? itemsPerPage : 'all',
        search: this.searchSuspendedEmployees || null,
        sort_by: sortBy ? sortBy[0] : null,
        order: sortBy ? (sortDesc[0] ? 'desc' : 'asc') : null
      }
      const { data: { data, meta: { total } } } = await this.getMemberSuspendedEmployees(params)
      this.tableTotalItems = total
      this.suspendedEmployees = data
      this.visibleSuspendedEmployeesItems = this.suspendedEmployees
      // this.visibleSuspendedEmployeesItems = this.suspendedEmployees.slice(0, 50)
      this.loadingSuspended = false
    },
    async getEmployees() {
      this.activeEmployees = []
      this.visibleActiveEmployeesItems = []
      this.loading = true
      const { itemsPerPage, page, sortBy, sortDesc } = this.allEmployeesOptions
      const params = {
        page,
        per_page: itemsPerPage > 0 ? itemsPerPage : 'all',
        search: this.searchActiveEmployees || null,
        sort_by: sortBy ? sortBy[0] : null,
        order: sortBy[0] ? (sortDesc[0] ? 'desc' : 'asc') : null
      }
      const { data, meta: { total } } = await employee.getEmployees(params)
      this.tableTotalItems = total
      this.activeEmployees = data
      this.visibleActiveEmployeesItems = this.activeEmployees
      // this.visibleActiveEmployeesItems = this.activeEmployees.slice(0, 50)
      this.loading = false
    },
    async getEmployeesRoles() {
      try {
        this.employeeRoles = await employee.getEmployeesRoles()
      } catch (err) {
        console.log(err)
      }
    },
    async getEmployeesToReport() {
      const memberId = this.$store.getters.user.profile_id.member_id
      try {
        this.employeesToReport = await employee.getEmployeesToReport(memberId)
      } catch (err) {
        console.log(err)
      }
    },
    async addEmployee() {
      try {
        await employee.addEmployee({
          id: this.editedItem.id,
          firstName: this.editedItem.firstName,
          voter_county_id: this.editedItem.voterCounty,
          secondName: this.editedItem.secondName,
          lastName: this.editedItem.lastName,
          userName: this.editedItem.userName,
          email: this.editedItem.email,
          secondaryEmail: this.editedItem.secondaryEmail,
          homePhone: this.editedItem.homePhone,
          workPhone: this.editedItem.workPhone,
          mobilePhone: this.editedItem.mobilePhone,
          mailAddress: this.editedItem.mailAddress,
          mailCity: this.editedItem.mailCity,
          mailState: this.editedItem.mailState,
          mailZip: this.editedItem.mailZip,
          voterAddress: this.editedItem.voterAddress,
          voterCity: this.editedItem.voterCity,
          voterState: this.editedItem.voterState,
          voterZip: this.editedItem.voterZip,
          county_id: this.editedItem.county,
          inelegible: this.editedItem.inelegible,
          disabled_email_notification_ba: this.editedItem.disabled_email_notification_ba,
          disabled_sms_notification_ba: this.editedItem.disabled_sms_notification_ba,
          vuid: this.editedItem.vuid,
          birthDate: this.editedItem.birthDate,
          employeeRoleId: this.editedItem.employeeRoleId,
          reportsTo: this.editedItem.reportsTo,
          eligibility_detail_id: this.editedItem.eligibilityDetailId
        })
        await this.getEmployees()
        this.showAlertMsg('success')
      } catch (err) {
        console.log(err)
      }
    },
    async editEmployee() {
      return {
        rescreen: this.isChanged,
        id: this.editedItem.id,
        firstName: this.editedItem.firstName,
        secondName: this.editedItem.secondName,
        voter_county_id: this.editedItem.voterCounty,
        lastName: this.editedItem.lastName,
        userName: this.editedItem.userName,
        email: this.editedItem.email,
        secondaryEmail: this.editedItem.secondaryEmail,
        homePhone: this.editedItem.homePhone,
        workPhone: this.editedItem.workPhone,
        mobilePhone: this.editedItem.mobilePhone,
        mailAddress: this.editedItem.mailAddress,
        mailCity: this.editedItem.mailCity,
        mailState: this.editedItem.mailState,
        mailZip: this.editedItem.mailZip,
        voterAddress: this.editedItem.voterAddress,
        voterCity: this.editedItem.voterCity,
        voterState: this.editedItem.voterState,
        voterZip: this.editedItem.voterZip,
        county_id: this.editedItem.county,
        inelegible: this.editedItem.inelegible,
        disabled_email_notification_ba: this.editedItem.disabled_email_notification_ba,
        disabled_sms_notification_ba: this.editedItem.disabled_sms_notification_ba,
        vuid: this.editedItem.vuid,
        birthDate: this.editedItem.birthDate,
        employeeRoleId: this.editedItem.employeeRoleId,
        reportsTo: this.editedItem.reportsTo,
        eligibilityDetailId: this.editedItem.inelegible ? this.editedItem.eligibilityDetailId : null
      }
    },
    async deleteEmployee() {
      try {
        await employee.deleteEmployee({ id: this.editedItem.id })
        await this.getSuspendedEmployees()
      } catch (err) {
        console.log(err)
      }
    },
    async deleteEmployees() {
      try {
        await employee.deleteEmployees({ employees: this.suspendedSelected })
        await this.getSuspendedEmployees()
      } catch (err) {
        console.log(err)
      }
    },
    async enableDisableEmployee() {
      try {
        await employee.enableDisableEmployee({ id: this.editedItem.id })
        await this.getEmployees()
      } catch (err) {
        console.log(err)
      }
    },
    async enableDisableEmployees() {
      try {
        if (this.suspend) {
          await employee.enableDisableEmployees({ employees: this.selected })
          await this.getEmployees()
          this.suspend = false
        } else if (this.reincorporate) {
          await employee.enableDisableEmployees({ employees: this.suspendedSelected })
          await this.getSuspendedEmployees()
          this.reincorporate = false
        }
      } catch (err) {
        console.log(err)
      }
    },
    hideAlertMsg() {
      window.setTimeout(() => {
        this.showSuccessAlert = false
        this.showSuspendAlert = false
        this.showDeleteAlert = false
      }, 5000)
    },
    showAlertMsg(type) {
      switch (type) {
        case 'success':
          this.showSuccessAlert = true
          break
        case 'delete':
          this.showDeleteAlert = true
          break
        case 'suspend':
          this.showSuspendAlert = true
          break
        case 'reincorporate':
          this.showReincorporateAlert = true
          break
      }
      this.hideAlertMsg()
    },
    async editItem(item) {
      this.isChanged = false
      this.employeeIndex = item.id
      this.loadingEditEmployee = true
      this.editedIndex = this.activeEmployees.indexOf(item)
      const res = await this.getEmployeeInfoToEdit(item.id)
      this.editedItem = Object.assign(
        {},
        {
          id: res.id,
          firstName: res.firstName,
          secondName: res.secondName,
          lastName: res.lastName,
          voterCounty: res.voterCounty.value ? res.voterCounty.value : null,
          userName: res.userName,
          email: res.email,
          secondaryEmail: res.secondaryEmail,
          homePhone: res.homePhone,
          workPhone: res.workPhone,
          mobilePhone: res.mobilePhone,
          mailAddress: res.mailAddress,
          mailCity: res.mailCity,
          mailState: res.mailState,
          mailZip: res.mailZip,
          voterAddress: res.voterAddress,
          voterCity: res.voterCity,
          voterState: res.voterState,
          voterZip: res.voterZip,
          county: res.county.value? res.county.value: null,
          inelegible: res.inelegible,
          disabled_email_notification_ba: res.disabledEmailNotificationBa,
          disabled_sms_notification_ba: res.disabledSmsNotificationBa,
          vuid: res.vuid,
          birthDate: res.birthDate,
          employeeRoleId: res.hierarchy?.id,
          reportsTo: res.reportsTo?.id,
          eligibilityDetailId: res.inelegible ? res.eligibilityDetailId : null
        }
      )
      await this.getEmployeesToReport()
      this.dialog = true
      this.loadingEditEmployee = false
    },
    deleteItem(item) {
      this.singleDelete = true
      this.editedIndex = this.suspendedEmployees.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    suspendItem(item) {
      this.singleSuspend = true
      this.editedIndex = this.activeEmployees.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogSuspend = true
    },
    async suspendEmployeeConfirm() {
      if (this.singleSuspend) {
        await this.enableDisableEmployee()
      } else {
        await this.suspendMultipleEmployees()
      }
      this.selected = []
      this.closeSuspend()
      this.showAlertMsg('suspend')
    },
    async suspendMultipleEmployees() {
      this.suspend = true
      await this.enableDisableEmployees()
    },
    async onSuspendBtnClick() {
      const hasSelectedEmployee = await this.checkSelectEmployee(this.selected)
      if (hasSelectedEmployee) {
        this.singleSuspend = false
        this.dialogSuspend = true
      }
    },
    onReincorporateBtnClick() {
      this.singleReincorporate = false
      this.dialogReincorporate = true
    },
    reincorporateItem(item) {
      this.singleReincorporate = true
      this.editedIndex = this.suspendedEmployees.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogReincorporate = true
      this.suspendedSelected = []
    },

    async reincorporateEmployeeConfirm() {
      if (this.singleReincorporate) {
        await this.enableDisableEmployee()
      } else {
        await this.reincorporateMultipleEmployees()
      }
      await this.getSuspendedEmployees()
      this.suspendedSelected = []
      this.closeReincorporate()
      this.showAlertMsg('reincorporate')
    },
    async reincorporateMultipleEmployees() {
      this.reincorporate = true
      await this.enableDisableEmployees()
    },
    onDeleteBtnClick() {
      this.singleDelete = false
      this.dialogDelete = true
    },
    async deleteMultipleEmployees() {
      await this.deleteEmployees()
      this.suspendedSelected = []
    },
    openDeleteConfirmation() {
      this.dialogDelete = false
      this.dialogDeleteConfirmation = true
      if (this.selected.length > 0)
        this.singleDelete = false
    },
    async deleteItemConfirm() {
      if (this.singleDelete) {
        await this.deleteEmployee()
        this.activeEmployees.splice(this.editedIndex, 1)
      } else {
        await this.deleteMultipleEmployees()
      }
      this.closeDeleteConfirmation()
      this.showAlertMsg('delete')
    },
    closeDeleteConfirmation() {
      this.dialogDeleteConfirmation = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    close() {
      this.isChanged = false
      this.searchNewEmployee = false
      this.successSearch = false
      this.selectedEmployees = []
      this.emptySearch = false
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.reset()
        }
      })
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$refs.form.reset()
    },
    closeDelete() {
      this.dialogDelete = false
    },
    closeSuspend() {
      this.dialogSuspend = false
    },
    closeReincorporate() {
      this.dialogReincorporate = false
    },
    onEmployeeClick(item) {
      this.$store.dispatch('employee', item)
      this.$router.push(`/employee/profile/${ item.id }`)
    },
    async addNewEmployee() {
      this.creating = true
      this.editing = false
      await this.addEmployee()
    },
    async createNewEmployee() {
      this.successSearch = false
      this.selectedEmployees = []
      this.emptySearch = false
      if (this.$refs.form.validate()) {
        await this.addNewEmployee()
        this.close()
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
        this.$refs.form.reset()
        this.searchNewEmployee = true
      }
    },
    async updateEmployee() {
      this.editing = true
      this.creating = false
      const employeeData = await this.editEmployee()
      this.loadingSave = true
      const res = await employee.editEmployeeByAdminWithoutRescreen(employeeData)
      this.loadingSave = false
      this.close()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$refs.form.reset()
      this.searchNewEmployee = true
      await this.getEmployees()
      this.ifFieldChanged()
      if (res) this.showAlertMsg('success')
    },
    async screenEmployee() {
      this.editing = true
      this.creating = false
      const employeeData = await this.editEmployee()
      this.loadingSubmit = true
      const res = await employee.editEmployeeByAdmin(employeeData)
      this.loadingSubmit = false
      this.close()
      if (res.data.length) {
        // popup
        this.rescreenDialog = true
        this.rescreenData = res.data
      } else {
        // message
        await this.$store.dispatch('snackbar', {
          show: true,
          color: 'success',
          message: res.message[0]
        })
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$refs.form.reset()
      this.searchNewEmployee = true
      await this.getEmployees()
      this.ifFieldChanged()
    },
    // onActiveEmployeesScroll() {
    //   const container = this.$refs.activeEmployeesTableRef
    //   if (container.scrollTop + container.clientHeight >= container.scrollHeight - 200) {
    //     const startIndex = this.visibleActiveEmployeesItems.length
    //     const endIndex = startIndex + 50
    //     this.visibleActiveEmployeesItems = this.visibleActiveEmployeesItems.concat(this.activeEmployees.slice(startIndex, endIndex))
    //   }
    // },
    // onSuspendedEmployeesScroll() {
    //   const container = this.$refs.suspendedEmployeesTableRef
    //   if (container.scrollTop + container.clientHeight >= container.scrollHeight - 200) {
    //     const startIndex = this.visibleSuspendedEmployeesItems.length
    //     const endIndex = startIndex + 50
    //     this.visibleSuspendedEmployeesItems = this.visibleSuspendedEmployeesItems.concat(this.suspendedEmployees.slice(startIndex, endIndex))
    //   }
    // }
  }
}
</script>

<style lang='scss' scoped>
.table-wrapper {
  max-height: 80vh;
  height: 100%;
  overflow-y: auto;
  ::v-deep .v-data-table__wrapper {
    padding-bottom: 80px;
  }
  ::v-deep .v-data-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
  }
}
.showPointer:hover {
  cursor: pointer;
}
.checkbox {
  height: 48px;
  width: 34px;
}
</style>
